import axios from 'axios';
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

class authService {
    login(username, password) {
        // data test
        let data = {
            token: 'f5cf21c2-d6d4-465a-b1f1-9c0c7d27f61d',
            expired_time: '2022-01-24T13:07:29.215575Z',
            created_at: "2021-06-17T10:31:56.000000Z",
            deleted_at: null,
            email: "admin@bustech.com",
            id: 1,
            last_login: "21/01/2022 21:07 PM",
            name: "Admin Test",
            permissions: [],
            role: {
                id: 1,
                name: "Admin",
                type: 'admin'
            },
            role_id: 1,
            status: "Active",
            updated_at: "2022-01-21T13:07:29.000000Z"
        };
        cookies.set("token", data.token, new Date(data.expired_time));
        localStorage.setItem("user", JSON.stringify(data));
        return data;
    }
      
    logout() {
        localStorage.removeItem("user");
        cookies.remove("token");
        return true;

        // return axios.get(`api/v1/logout`).then(data => {
        //     if (data.success) {
        //         localStorage.removeItem("user");
        //         cookies.remove("token");
            
        //         return true;
        //     } else {
        //         return data.message;
        //     }
        // });
    }
}
export default new authService();



import authService from '@/services/auth.service.js';
import { useCookies } from "vue3-cookies";
import router from '@/router';

const user = JSON.parse(localStorage.getItem("user"));
const { cookies } = useCookies();
const token = cookies.get("token");

const getPermissionArr = permissions => {
  return permissions.map(element => {
    return element.slug;
  });
};

const state = () => {
  return user && token
    ? {
        status: { loggeduser: true },
        user,
        token,
        permissions: getPermissionArr(user.permissions),
        userType: user.role.type,
      }
    : {
        status: {},
        user: null,
        token: null,
        permissions: null,
        userType: null,
      };
};

export const authfack = {
    namespaced: true,
    state: state,
    getters: {
        getToken: state => {
            return state ? state.token : null;
        },
        getPermissions: state => {
            let permissions = state.permissions ? [...state.permissions] : [];
            return permissions;
        },
        getUser: state => {
            return state ? state.user : null;
        },
        getUserType: state => {
            return state ? state.userType : null;
        },
    },
    actions: {
        login({ dispatch, commit }, { username, password }) {
            commit("loginRequest", { username });
            let result = authService.login(username, password);
            if (result.token && result.id) {
                commit("loginSuccess", {
                    user: result,
                    token: result.token,
                    permissions: getPermissionArr(result.permissions)
                });
                router.push({ path: "/" });
            } else {
                commit("loginFailure");
            }
        },
        async logout({ dispatch, commit }) {
            let result = await authService.logout();
            if (result === true) {
                commit("logout");
                router.push({ path: "/login" });
            } else {
                dispatch("modules/notification/error", result, { root: true });
            }
        },
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, { user, token, permissions }) {
            state.status = { loggeduser: true };
            state.user = user;
            state.token = token;
            state.permissions = permissions;
            state.userType = user.role.type;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
            state.token = null;
            state.userType = null;
            state.permissions = null;
        },
    }
};

<template>
	<div>
		<router-view />
	</div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
	data() {
		return {
			currentMenu: "home",
		};
	},
};
</script>
